import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Lazy loading pages
const LoginPage = lazy(() => import("./components/LoginPage"));
const DashboardPage = lazy(() => import("./components/DashboardPage"));
const CreateProfilePage = lazy(() => import("./components/CreateProfilePage"));
const UploadPage = lazy(() => import("./components/UploadPage"));
const ClassifyPage = lazy(() => import("./components/ClassifyPage"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage")); // A 404 page for unknown routes

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/create-profile" element={<CreateProfilePage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/classify" element={<ClassifyPage />} />
          {/* Catch-all route for unknown paths */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
